<template>
  <div>
    <div class="accelerate">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">Merchants Accelerate Growth</div>
          <div class="content margin-top-15">Hyper-growth merchants like SeatGeek and Rappi focus on building a great customer experience and optimizing revenue from each transaction. Not on managing complex payment infrastructure or PCI and 3DS challenges.</div>
        </div>
        <img src="../../assets/images/use_cases_merchant/p1.png" />
      </div>
    </div>
    <div class="orchestration">
      <div class="inner">
        <div class="title">
          <div>With moozumi’s Payment<br/> Orchestration solution, you can:</div>
        </div>
        <div class="content-list">
          <template v-for="(item, index) in solutions">
            <div :key="index" class="content-item margin-top-50">
              <img class="icon" :src="item.icon" />
              <div class="content">{{item.content}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="differentiation">
      <div class="inner">
        <div class="title">
          <div>Keep Development Teams Focused on Differentiation</div>
        </div>
        <div class="content">
          <div>Your development team shouldn’t develop your unique payments infrastructure from the ground up. You want them building value for customers – not maintaining regulatory compliance and sophisticated payments systems.</div>
          <div class="margin-top-20">At hundreds of global brands, moozumi helps merchants minimize PCI, 3DS, and other security and regulatory burdens. Our payment orchestration platform lets you rapidly add new payment service connections via a single API.</div>
        </div>
        <div class="list-items">
          <template v-for="(item, index) in differentiations">
            <div :key="index" class="item">
              <img src="../../assets/images/use_cases_merchant/icon.png" />
              <div class="text">{{item}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="effect">
      <div class="grow">
        <div class="content-wrapper">
          <div class="title">
            <div>Grow Your Business in New Markets</div>
          </div>
          <div class="content">
            <div>You want potential customers in new markets to transact with you effectively. But high decline rates, local payment methods, and unavailable payment services can be the difference between a successful launch and a disaster.</div>
            <div class="margin-top-20">moozumi’s global ecosystem connects you to virtually any payment gateway, fraud, or other service. Leverage our platform’s connections to successfully transact with your customers – around the world.</div>
          </div>
        </div>
        <img src="../../assets/images/use_cases_merchant/p2.png" />
      </div>
      <div class="optimize">
        <img src="../../assets/images/use_cases_merchant/p3.png" />
        <div class="content-wrapper">
          <div class="title">
            <div>Optimize Every Transaction to Drive More Revenue</div>
          </div>
          <div class="content">
            <div>A great payment experience lets you capture immediate revenue – and also future purchases or subscriptions. But false declines and lowered authorization rates eat into your revenue and disrupt your customer experience.</div>
            <div class="margin-top-20">moozumi’s smart routing capabilities ensure that transactions get routed to the right service to optimize success rates and transaction costs. Built on systems that process $40 billion in GMV per year, you can depend on the intelligence in our platform.</div>
          </div>
        </div>
      </div>
    </div>
    <MoozumiEmail></MoozumiEmail>
  </div>
</template>
<script>
import MoozumiEmail from '../../components/MoozumiEmail.vue'

export default {
  components: { MoozumiEmail },
  data () {
    return {
      solutions: [
        {
          icon: require('../../assets/images/use_cases_merchant/icon1.png'),
          content: 'Focus Developer Resources away from building PCI compliance and multiple gateway connections'
        },
        {
          icon: require('../../assets/images/use_cases_merchant/icon2.png'),
          content: 'Grow Faster by entering new markets with the right mix of payment services'
        },
        {
          icon: require('../../assets/images/use_cases_merchant/icon3.png'),
          content: 'Maximize Revenue by optimizing each transaction for revenue, cost, and customer experience'
        }
      ],
      differentiations: [
        'Outsource regulatory and security burdens like PCI and SCA / PSD2 to our experts',
        'Connect to virtually any payment service via a single API rather than building complex integrations',
        'Leverage our experience across billions of transactions to enhance your payments strategy'
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/variables.scss';
.accelerate {
  background: url(../../assets/images/use_cases_merchant/bg1.png);
  background-size: cover;
  padding: 51px 0 12px;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .left-wrapper {
      width: 460px;
      max-width: 100%;
      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: $primary-color1;
        letter-spacing: 0;
        text-align: left;
        line-height: 68px;
      }
      .content {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: $content-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
      }
    }
    img {
      width: 660px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.orchestration {
  padding: 120px 0;
  background: url(../../assets/images/use_cases_merchant/bg2.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content-item {
        width: 330px;
        box-shadow: 0 0 77px 0 rgb(0 0 0 / 6%);
        background: $white;
        border-radius: 20px;
        text-align: left;
        padding: 32px 24px;
        .icon {
          width: 60px;
        }
        .content {
          font-family: HelveticaNeue;
          font-size: 20px;
          color: $nav-title-color;
          letter-spacing: 0;
          text-align: left;
          line-height: 36px;
          margin-top: 24px;
        }
      }
    }
  }
}
.differentiation {
  padding: 120px 0;
  background: url(../../assets/images/use_cases_merchant/bg3.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content {
      margin-top: 15px;
      font-family: HelveticaNeue;
      font-size: 20px;
      color: $white;
      letter-spacing: 0;
      text-align: left;
      line-height: 36px;
    }
    .list-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        border-left: 1px solid #2A6095;
        width: 350px;
        margin-top: 50px;
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $white;
        letter-spacing: 0;
        text-align: left;
        line-height: 32px;
        display: flex;
        justify-content: space-around;
        img {
          width: 19px;
          height: 19px;
        }
        .text {
          width: 300px;
          margin-top: -6px;
        }
      }
    }
  }
}
.effect {
  padding: 60px 0;
  background: url(../../assets/images/use_cases_merchant/bg4.png);
  background-size: cover;
  .grow, .optimize {
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    img {
      width: 462px;
      max-width: 100%;
      box-sizing: border-box;
    }
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content-wrapper {
      width: 602px;
      max-width: 100%;
      box-sizing: border-box;
      .content {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
        margin-top: 16px;
      }
    }
  }
  .optimize {
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .accelerate {
    .inner {
      padding: 0 15px;
    }
  }
  .orchestration {
    .inner {
      padding: 0 15px;
    }
  }
  .differentiation {
    .inner {
      padding: 0 15px;
    }
  }
  .effect {
    .grow, .optimize {
      padding-left: 15px;
      padding-right: 15px;
    }
    .grow {
      img {
        margin-top: 20px;
      }
    }
    .optimize {
      .content-wrapper {
        margin-top: 20px;
      }
    }
  }
}
</style>
